import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import Seo from "../components/seo"
import Articles from "../components/articles"

const Tag = ({ pageContext, data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes
  const { tag } = pageContext
  const title = "タグ「" + tag + "」の含まれる記事一覧"
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={title} />
      <h1 className="archive-title">{title}</h1>
      <Articles posts={posts} />
    </Layout>
  )
}

export default Tag

export const pageQuery = graphql`
    query($tag: String) {
        site {
            siteMetadata {
                title
            }
        }
        allMdx(
            limit: 10,
            sort: { fields: [frontmatter___date], order: DESC },
            filter: { frontmatter: { 
                tag: { eq: $tag },
                status: {eq: "publish"}
            } }
        ) {
            nodes {
                excerpt
                slug
                frontmatter {
                    date(formatString: "YYYY/MM/DD")
                    title
                    category
                    tag
                }
            }
            pageInfo {
                currentPage
                hasNextPage
                hasPreviousPage
                itemCount
                pageCount
                perPage
                totalCount
            }
        }
    }
`